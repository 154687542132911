import { Http } from '../utils/http'

const PREFIX_URL = '/user/myAppointment'

// 开票相关接口
export class InvoiceModel extends Http {
  // 创建挂号的开票单据
  create(registerid) {
    return this.post(`${PREFIX_URL}/createInvoice`,  { registerid }, { isFormData:true })
  }

  // 创建处方的开票单据
  createPrescriptionInvoice(cfddbm) {
    return this.post('/user/myPrescription/createInvoice',  { cfddbm }, { isFormData:true })
  }

  // 查询发票信息 orderNumber: 订单号
  query(orderNumber) {
    return this.post(`${PREFIX_URL}/queryInvoiceByOrderNumber`, { orderNumber }, { isFormData:true })
  }

  /**
   * 发送邮件
   * @param email 邮箱地址
   * @param orderNumber 订单号
   * @returns {Promise | Promise<any>}
   */
  sendEmail(email, orderNumber) {
    return this.post(`${PREFIX_URL}/sendEmail`,  { email, orderNumber }, { isFormData:true })
  }

  getPdfStream(orderNumber) {
    return this.get(`${PREFIX_URL}/queryInvoiceFile/${orderNumber}`, undefined, { headers: {responseType: 'blob'} })
  }
}

export const invocieModel = new InvoiceModel()
