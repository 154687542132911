import { render, staticRenderFns } from "./expenseList.vue?vue&type=template&id=bb0a2eae&scoped=true"
import script from "./expenseList.vue?vue&type=script&lang=js"
export * from "./expenseList.vue?vue&type=script&lang=js"
import style0 from "./expenseList.vue?vue&type=style&index=0&id=bb0a2eae&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb0a2eae",
  null
  
)

export default component.exports